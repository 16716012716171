var Handlebars = require("handlebars");
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"block full\">\r\n    <div class=\"block-title\">\r\n        <h2 class=\"w-100\">\r\n            "
    + alias2(require("C:\\Users\\Admin\\Coding\\app-cash-register-ui\\app\\scripts\\modules\\common\\templates\\helpers\\translate.js").call(alias1,"outstanding_invoices",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":4,"column":12},"end":{"line":4,"column":55}}}))
    + ":\r\n            "
    + alias2(require("C:\\Users\\Admin\\Coding\\app-cash-register-ui\\app\\scripts\\modules\\common\\templates\\helpers\\fullRelationName.js").call(alias1,depth0,{"name":"common$fullRelationName","hash":{},"data":data,"loc":{"start":{"line":5,"column":12},"end":{"line":5,"column":41}}}))
    + "\r\n            <span class=\"pull-right\">\r\n                "
    + alias2(require("C:\\Users\\Admin\\Coding\\app-cash-register-ui\\app\\scripts\\modules\\common\\templates\\helpers\\translate.js").call(alias1,"total",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":7,"column":16},"end":{"line":7,"column":44}}}))
    + ":\r\n                "
    + alias2(require("C:\\Users\\Admin\\Coding\\app-cash-register-ui\\app\\scripts\\modules\\common\\templates\\helpers\\currencyFormat.js").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"outstanding_invoice_stats") : depth0)) != null ? lookupProperty(stack1,"currency_iso3") : stack1),((stack1 = (depth0 != null ? lookupProperty(depth0,"outstanding_invoice_stats") : depth0)) != null ? lookupProperty(stack1,"value_outstanding_wt") : stack1),{"name":"common$currencyFormat","hash":{},"data":data,"loc":{"start":{"line":8,"column":16},"end":{"line":8,"column":129}}}))
    + "\r\n            </span>\r\n        </h2>\r\n    </div>\r\n\r\n    <div class=\"block table-container\">\r\n        <div class=\"loader-wrapper\"><div class=\"loader\"></div></div>\r\n        <table class=\"table\">\r\n            <thead>\r\n            <tr>\r\n                <th>\r\n                    "
    + alias2(require("C:\\Users\\Admin\\Coding\\app-cash-register-ui\\app\\scripts\\modules\\common\\templates\\helpers\\translate.js").call(alias1,"invoice",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":19,"column":20},"end":{"line":19,"column":50}}}))
    + "\r\n                </th>\r\n                <th>\r\n                    "
    + alias2(require("C:\\Users\\Admin\\Coding\\app-cash-register-ui\\app\\scripts\\modules\\common\\templates\\helpers\\translate.js").call(alias1,"outstanding",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":22,"column":20},"end":{"line":22,"column":54}}}))
    + "\r\n                </th>\r\n                <th>\r\n                    "
    + alias2(require("C:\\Users\\Admin\\Coding\\app-cash-register-ui\\app\\scripts\\modules\\common\\templates\\helpers\\translate.js").call(alias1,"pay",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":25,"column":20},"end":{"line":25,"column":46}}}))
    + "\r\n                </th>\r\n            </tr>\r\n            </thead>\r\n            <tbody></tbody>\r\n        </table>\r\n    </div>\r\n\r\n    <br>\r\n\r\n    <button class=\"btn btn-default btn-regular\" data-action=\"back\">\r\n        <i class=\"fas fa-arrow-left\"></i>\r\n        "
    + alias2(require("C:\\Users\\Admin\\Coding\\app-cash-register-ui\\app\\scripts\\modules\\common\\templates\\helpers\\translate.js").call(alias1,"back",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":37,"column":8},"end":{"line":37,"column":35}}}))
    + "\r\n    </button>\r\n\r\n</div>\r\n";
},"useData":true});