var Handlebars = require("handlebars");
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "                <button id=\"print\" class=\"btn btn-primary btn-regular btn-lg\"><i class=\"fa fa-print\"></i>"
    + container.escapeExpression(require("C:\\Users\\Admin\\Coding\\app-cash-register-ui\\app\\scripts\\modules\\common\\templates\\helpers\\translate.js").call(depth0 != null ? depth0 : (container.nullContext || {}),"print",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":6,"column":105},"end":{"line":6,"column":133}}}))
    + "</button>\r\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"content designer-box rounded\">\r\n    <h2>\r\n        "
    + alias2(require("C:\\Users\\Admin\\Coding\\app-cash-register-ui\\app\\scripts\\modules\\common\\templates\\helpers\\translate.js").call(alias1,"cash_up",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":3,"column":8},"end":{"line":3,"column":38}}}))
    + " ("
    + alias2(((helper = (helper = lookupProperty(helpers,"dayShowing") || (depth0 != null ? lookupProperty(depth0,"dayShowing") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"dayShowing","hash":{},"data":data,"loc":{"start":{"line":3,"column":40},"end":{"line":3,"column":54}}}) : helper)))
    + ")\r\n        <span class=\"pull-right\">\r\n"
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"isInWebOrAppMode") : depth0),{"name":"unless","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":5,"column":12},"end":{"line":7,"column":23}}})) != null ? stack1 : "")
    + "        </span>\r\n    </h2>\r\n\r\n    <hr>\r\n\r\n    <div class=\"row\">\r\n        <div class=\"col-xs-7\" data-ui=\"ticket\">\r\n            <form onsubmit=\"return false;\" method=\"post\">\r\n                <div class=\"form-group row\" style=\"margin-bottom: 5px;\">\r\n                    <label class=\"control-label col-md-6\">"
    + alias2(require("C:\\Users\\Admin\\Coding\\app-cash-register-ui\\app\\scripts\\modules\\common\\templates\\helpers\\translate.js").call(alias1,"expected_cash_total",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":17,"column":58},"end":{"line":17,"column":100}}}))
    + "</label>\r\n                    <div class=\"col-md-6 text-right\" style=\"padding-right: 63px;\">\r\n                        "
    + alias2(require("C:\\Users\\Admin\\Coding\\app-cash-register-ui\\app\\scripts\\modules\\common\\templates\\helpers\\currencyFormat.js").call(alias1,(depth0 != null ? lookupProperty(depth0,"iso3") : depth0),(depth0 != null ? lookupProperty(depth0,"expectedCash") : depth0),{"name":"common$currencyFormat","hash":{},"data":data,"loc":{"start":{"line":19,"column":24},"end":{"line":19,"column":67}}}))
    + "\r\n                    </div>\r\n                </div>\r\n                <div class=\"form-group row\">\r\n                    <label class=\"control-label vertical-align col-md-6\">"
    + alias2(require("C:\\Users\\Admin\\Coding\\app-cash-register-ui\\app\\scripts\\modules\\common\\templates\\helpers\\translate.js").call(alias1,"new_cash_amount_left",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":23,"column":73},"end":{"line":23,"column":116}}}))
    + " <span class=\"text-danger\">*</span></label>\r\n                    <div class=\"col-md-6\">\r\n                        <div class=\"input-group\">\r\n                            <div data-region=\"cash-in-register\">\r\n\r\n                            </div>\r\n                            <span class=\"input-group-btn\">\r\n                                <button data-action=\"cash-in-register-helper\" class=\"btn btn-primary btn-regular\" type=\"button\"><i class=\"fa fa-coins\" style=\"margin-right: 0;\"></i></button>\r\n                            </span>\r\n                        </div>\r\n                    </div>\r\n                </div>\r\n                <div class=\"form-group row\">\r\n                    <label class=\"control-label vertical-align col-md-6\">"
    + alias2(require("C:\\Users\\Admin\\Coding\\app-cash-register-ui\\app\\scripts\\modules\\common\\templates\\helpers\\translate.js").call(alias1,"cash_skimmed",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":36,"column":73},"end":{"line":36,"column":108}}}))
    + " <span class=\"text-danger\">*</span></label>\r\n                    <div class=\"col-md-6\">\r\n                        <div class=\"input-group\">\r\n                            <div data-region=\"cash-skimmed\">\r\n\r\n                            </div>\r\n                            <span class=\"input-group-btn\">\r\n                                <button data-action=\"cash-skimmed-helper\" class=\"btn btn-primary btn-regular\" type=\"button\"><i class=\"fa fa-coins\" style=\"margin-right: 0;\"></i></button>\r\n                            </span>\r\n                        </div>\r\n                    </div>\r\n                </div>\r\n                <div class=\"form-group row\" style=\"margin-bottom: 5px; margin-top: 10px;\">\r\n                    <label class=\"control-label col-md-6\">"
    + alias2(require("C:\\Users\\Admin\\Coding\\app-cash-register-ui\\app\\scripts\\modules\\common\\templates\\helpers\\translate.js").call(alias1,"difference",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":49,"column":58},"end":{"line":49,"column":91}}}))
    + "</label>\r\n                    <div class=\"col-md-6 text-right\" style=\"padding-right: 63px;\" id=\"difference-cash\">\r\n                        "
    + alias2(require("C:\\Users\\Admin\\Coding\\app-cash-register-ui\\app\\scripts\\modules\\common\\templates\\helpers\\currencyFormat.js").call(alias1,(depth0 != null ? lookupProperty(depth0,"iso3") : depth0),(depth0 != null ? lookupProperty(depth0,"expectedCash") : depth0),{"name":"common$currencyFormat","hash":{},"data":data,"loc":{"start":{"line":51,"column":24},"end":{"line":51,"column":67}}}))
    + "\r\n                    </div>\r\n                </div>\r\n\r\n                <hr>\r\n\r\n                <div class=\"form-group row\">\r\n                    <label class=\"control-label vertical-align col-md-6\">"
    + alias2(require("C:\\Users\\Admin\\Coding\\app-cash-register-ui\\app\\scripts\\modules\\common\\templates\\helpers\\translate.js").call(alias1,"remarks",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":58,"column":73},"end":{"line":58,"column":103}}}))
    + "</label>\r\n                    <div class=\"col-md-6\">\r\n                        <textarea id=\"note\" rows=\"6\" class=\"form-control\" placeholder=\""
    + alias2(require("C:\\Users\\Admin\\Coding\\app-cash-register-ui\\app\\scripts\\modules\\common\\templates\\helpers\\translate.js").call(alias1,"remarks_for_the_cash_up",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":60,"column":87},"end":{"line":60,"column":133}}}))
    + "\"></textarea>\r\n                    </div>\r\n                </div>\r\n                <div class=\"form-group form-actions\" style=\"margin-top: 30px;\">\r\n                    <button class=\"btn btn-lg btn-default btn-regular\" id=\"back\">\r\n                        <i class=\"fa fa-arrow-left\"></i> "
    + alias2(require("C:\\Users\\Admin\\Coding\\app-cash-register-ui\\app\\scripts\\modules\\common\\templates\\helpers\\translate.js").call(alias1,"go_back",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":65,"column":57},"end":{"line":65,"column":87}}}))
    + "\r\n                    </button>\r\n                    <button class=\"btn btn-lg btn-primary btn-regular pull-right\" id=\"finalize\">\r\n                        <i class=\"fa fa-check\"></i> "
    + alias2(require("C:\\Users\\Admin\\Coding\\app-cash-register-ui\\app\\scripts\\modules\\common\\templates\\helpers\\translate.js").call(alias1,"finalize",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":68,"column":52},"end":{"line":68,"column":83}}}))
    + "\r\n                    </button>\r\n                </div>\r\n            </form>\r\n        </div>\r\n        <div class=\"col-xs-5\">\r\n            <div data-region=\"keypad\" class=\"keypad\" style=\"height: 384px\"></div>\r\n        </div>\r\n    </div>\r\n</div>";
},"useData":true});