var Handlebars = require("handlebars");
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression;

  return "<button class=\"btn designer-box rounded\">\r\n    "
    + alias2(require("C:\\Users\\Admin\\Coding\\app-cash-register-ui\\app\\scripts\\modules\\common\\templates\\helpers\\translate.js").call(alias1,"scan_customer_card",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":2,"column":4},"end":{"line":2,"column":45}}}))
    + " "
    + alias2(require("C:\\Users\\Admin\\Coding\\app-cash-register-ui\\app\\scripts\\modules\\common\\templates\\helpers\\translate.js").call(alias1,"or",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":2,"column":46},"end":{"line":2,"column":71}}}))
    + " "
    + alias2(require("C:\\Users\\Admin\\Coding\\app-cash-register-ui\\app\\scripts\\modules\\common\\templates\\helpers\\translate.js").call(alias1,"search_for_customer",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":2,"column":72},"end":{"line":2,"column":114}}}))
    + "\r\n</button>";
},"useData":true});