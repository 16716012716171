var Handlebars = require("handlebars");
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "                <i class=\"far fa-user-circle text-primary fa-2x customer-icon\"></i>\r\n";
},"3":function(container,depth0,helpers,partials,data) {
    return "                <i class=\"far fa-building text-primary fa-2x customer-icon\"></i>\r\n";
},"5":function(container,depth0,helpers,partials,data) {
    return "                        <button class=\"btn btn-primary btn-regular\" data-action=\"outstanding\">\r\n                            <i class=\"fa fa-euro-sign\"></i>"
    + container.escapeExpression(require("C:\\Users\\Admin\\Coding\\app-cash-register-ui\\app\\scripts\\modules\\common\\templates\\helpers\\translate.js").call(depth0 != null ? depth0 : (container.nullContext || {}),"outstanding",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":21,"column":59},"end":{"line":21,"column":93}}}))
    + "\r\n                        </button>\r\n";
},"7":function(container,depth0,helpers,partials,data) {
    return "                            "
    + container.escapeExpression(require("C:\\Users\\Admin\\Coding\\app-cash-register-ui\\app\\scripts\\modules\\common\\templates\\helpers\\translate.js").call(depth0 != null ? depth0 : (container.nullContext || {}),"customer",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":46,"column":28},"end":{"line":46,"column":59}}}))
    + "\r\n";
},"9":function(container,depth0,helpers,partials,data) {
    return "                            "
    + container.escapeExpression(require("C:\\Users\\Admin\\Coding\\app-cash-register-ui\\app\\scripts\\modules\\common\\templates\\helpers\\translate.js").call(depth0 != null ? depth0 : (container.nullContext || {}),"company_name",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":48,"column":28},"end":{"line":48,"column":63}}}))
    + "\r\n";
},"11":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? lookupProperty(depth0,"contact_person") : depth0)) != null ? lookupProperty(stack1,"birthdate") : stack1),{"name":"if","hash":{},"fn":container.program(12, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":84,"column":20},"end":{"line":93,"column":27}}})) != null ? stack1 : "");
},"12":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                        <div class=\"form-group row\">\r\n                            <label for=\"email\" class=\"control-label vertical-align col-md-5\">\r\n                                "
    + alias2(require("C:\\Users\\Admin\\Coding\\app-cash-register-ui\\app\\scripts\\modules\\common\\templates\\helpers\\translate.js").call(alias1,"birthday",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":87,"column":32},"end":{"line":87,"column":63}}}))
    + "\r\n                            </label>\r\n                            <div class=\"col-md-7 form-control-static\">\r\n                                "
    + alias2(require("C:\\Users\\Admin\\Coding\\app-cash-register-ui\\app\\scripts\\modules\\common\\templates\\helpers\\datetime.js").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"contact_person") : depth0)) != null ? lookupProperty(stack1,"birthdate") : stack1),"LL",{"name":"common$datetime","hash":{},"data":data,"loc":{"start":{"line":90,"column":32},"end":{"line":90,"column":81}}}))
    + "\r\n                            </div>\r\n                        </div>\r\n";
},"14":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    <div class=\"form-group row\">\r\n                        <label for=\"email\" class=\"control-label vertical-align col-md-5\">\r\n                            "
    + alias2(require("C:\\Users\\Admin\\Coding\\app-cash-register-ui\\app\\scripts\\modules\\common\\templates\\helpers\\translate.js").call(alias1,"outstanding_balance",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":99,"column":28},"end":{"line":99,"column":70}}}))
    + "\r\n                        </label>\r\n                        <div class=\"col-md-7 form-control-static\">\r\n                            "
    + alias2(require("C:\\Users\\Admin\\Coding\\app-cash-register-ui\\app\\scripts\\modules\\common\\templates\\helpers\\currencyFormat.js").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"outstanding_invoice_stats") : depth0)) != null ? lookupProperty(stack1,"currency_iso3") : stack1),((stack1 = (depth0 != null ? lookupProperty(depth0,"outstanding_invoice_stats") : depth0)) != null ? lookupProperty(stack1,"value_outstanding_wt") : stack1),{"name":"common$currencyFormat","hash":{},"data":data,"loc":{"start":{"line":102,"column":28},"end":{"line":102,"column":141}}}))
    + "\r\n"
    + ((stack1 = require("C:\\Users\\Admin\\Coding\\app-cash-register-ui\\app\\scripts\\modules\\common\\templates\\helpers\\compare.js").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"outstanding_invoice_stats") : depth0)) != null ? lookupProperty(stack1,"value_outstanding_wt") : stack1),">",0,{"name":"common$compare","hash":{},"fn":container.program(15, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":103,"column":28},"end":{"line":107,"column":47}}})) != null ? stack1 : "")
    + "                        </div>\r\n                    </div>\r\n";
},"15":function(container,depth0,helpers,partials,data) {
    return "                                <button class=\"btn btn-primary btn-regular\" data-action=\"outstanding\">\r\n                                    <i class=\"fas fa-euro-sign\"></i>\r\n                                </button>\r\n";
},"17":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"loyalty_program_active") : depth0),{"name":"if","hash":{},"fn":container.program(18, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":113,"column":20},"end":{"line":140,"column":27}}})) != null ? stack1 : "");
},"18":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                        <div class=\"form-group row\">\r\n                            <label for=\"email\" class=\"control-label vertical-align col-md-5\">\r\n                                "
    + container.escapeExpression(require("C:\\Users\\Admin\\Coding\\app-cash-register-ui\\app\\scripts\\modules\\common\\templates\\helpers\\translate.js").call(alias1,"loyalty_program",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":116,"column":32},"end":{"line":116,"column":70}}}))
    + "\r\n                            </label>\r\n                            <div class=\"col-md-7 form-control-static\">\r\n\r\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"loyalty_customer") : depth0),{"name":"if","hash":{},"fn":container.program(19, data, 0),"inverse":container.program(21, data, 0),"data":data,"loc":{"start":{"line":120,"column":32},"end":{"line":129,"column":39}}})) != null ? stack1 : "")
    + "                            </div>\r\n                        </div>\r\n\r\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"loyalty_customer") : depth0),{"name":"if","hash":{},"fn":container.program(23, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":133,"column":24},"end":{"line":139,"column":31}}})) != null ? stack1 : "");
},"19":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                                    "
    + alias2(require("C:\\Users\\Admin\\Coding\\app-cash-register-ui\\app\\scripts\\modules\\common\\templates\\helpers\\translate.js").call(alias1,"{point_total}_points_s",(depth0 != null ? lookupProperty(depth0,"loyalty_customer") : depth0),{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":121,"column":36},"end":{"line":121,"column":98}}}))
    + "\r\n                                    <button class=\"btn btn-primary btn-regular\" data-action=\"loyalty\">\r\n                                        "
    + alias2(require("C:\\Users\\Admin\\Coding\\app-cash-register-ui\\app\\scripts\\modules\\common\\templates\\helpers\\translate.js").call(alias1,"manage",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":123,"column":40},"end":{"line":123,"column":69}}}))
    + "\r\n                                    </button>\r\n";
},"21":function(container,depth0,helpers,partials,data) {
    return "                                    <button class=\"btn btn-primary btn-regular\" data-action=\"loyalty-join\">\r\n                                        "
    + container.escapeExpression(require("C:\\Users\\Admin\\Coding\\app-cash-register-ui\\app\\scripts\\modules\\common\\templates\\helpers\\translate.js").call(depth0 != null ? depth0 : (container.nullContext || {}),"join_program",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":127,"column":40},"end":{"line":127,"column":75}}}))
    + "\r\n                                    </button>\r\n";
},"23":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"unless").call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? lookupProperty(depth0,"loyalty_customer") : depth0)) != null ? lookupProperty(stack1,"active") : stack1),{"name":"unless","hash":{},"fn":container.program(24, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":134,"column":28},"end":{"line":138,"column":39}}})) != null ? stack1 : "");
},"24":function(container,depth0,helpers,partials,data) {
    return "                                <div class=\"alert alert-warning\">\r\n                                    "
    + container.escapeExpression(require("C:\\Users\\Admin\\Coding\\app-cash-register-ui\\app\\scripts\\modules\\common\\templates\\helpers\\translate.js").call(depth0 != null ? depth0 : (container.nullContext || {}),"this_customer_is_excluded_form_loyalty_program_heshe_will_not_get_any_automatic_points_counted_you_can_still_addremove_points_manually",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":136,"column":36},"end":{"line":136,"column":193}}}))
    + "\r\n                                </div>\r\n";
},"26":function(container,depth0,helpers,partials,data) {
    return "                            <i class=\"fas fa-check-square text-success\"></i>\r\n";
},"28":function(container,depth0,helpers,partials,data) {
    return "                            <i class=\"fas fa-times-square text-danger\"></i>\r\n";
},"30":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                            "
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"permanent_discount") || (depth0 != null ? lookupProperty(depth0,"permanent_discount") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"permanent_discount","hash":{},"data":data,"loc":{"start":{"line":192,"column":28},"end":{"line":192,"column":50}}}) : helper)))
    + "%\r\n";
},"32":function(container,depth0,helpers,partials,data) {
    return "                            -\r\n";
},"34":function(container,depth0,helpers,partials,data) {
    return "                <button class=\"btn btn-primary btn-regular\" data-action=\"display\">\r\n                    <i class=\"fa fa-eye\"></i>"
    + container.escapeExpression(require("C:\\Users\\Admin\\Coding\\app-cash-register-ui\\app\\scripts\\modules\\common\\templates\\helpers\\translate.js").call(depth0 != null ? depth0 : (container.nullContext || {}),"show_on_customer_screen",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":214,"column":45},"end":{"line":214,"column":91}}}))
    + "\r\n                </button>\r\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, alias3=container.lambda, alias4=container.hooks.helperMissing, alias5="function", lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"block full designer-box rounded\">\r\n    <div class=\"block-title\">\r\n        <h2 class=\"customer-title\">\r\n\r\n            <i class=\"far fa-arrow-alt-circle-left text-primary fa-2x back\" data-action=\"back\"></i>\r\n\r\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"isprivate") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data,"loc":{"start":{"line":7,"column":12},"end":{"line":11,"column":19}}})) != null ? stack1 : "")
    + "\r\n            <span class=\"h3 text-ellipsis customer-name\">\r\n                "
    + alias2(require("C:\\Users\\Admin\\Coding\\app-cash-register-ui\\app\\scripts\\modules\\common\\templates\\helpers\\fullRelationName.js").call(alias1,depth0,{"name":"common$fullRelationName","hash":{},"data":data,"loc":{"start":{"line":14,"column":16},"end":{"line":14,"column":45}}}))
    + "\r\n            </span>\r\n\r\n            <div class=\"block-options block-option-right\">\r\n                <div class=\"btn-group\">\r\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"isInvoiceFeatureEnabled") : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":19,"column":20},"end":{"line":23,"column":27}}})) != null ? stack1 : "")
    + "\r\n                    <button class=\"btn btn-primary btn-regular\" data-action=\"order-list\">\r\n                        <i class=\"fa fa-list\"></i>"
    + alias2(require("C:\\Users\\Admin\\Coding\\app-cash-register-ui\\app\\scripts\\modules\\common\\templates\\helpers\\translate.js").call(alias1,"orders",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":26,"column":50},"end":{"line":26,"column":79}}}))
    + "\r\n                    </button>\r\n                    <button class=\"btn btn-primary btn-regular\" data-action=\"edit\">\r\n                        <i class=\"fa fa-pencil\"></i>"
    + alias2(require("C:\\Users\\Admin\\Coding\\app-cash-register-ui\\app\\scripts\\modules\\common\\templates\\helpers\\translate.js").call(alias1,"edit_customer",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":29,"column":52},"end":{"line":29,"column":88}}}))
    + "\r\n                    </button>\r\n                </div>\r\n            </div>\r\n\r\n        </h2>\r\n    </div>\r\n\r\n\r\n    <form onsubmit=\"return false;\" method=\"post\">\r\n        <div class=\"row\">\r\n\r\n            <div class=\"col-xs-6\">\r\n\r\n                <div class=\"form-group row\">\r\n                    <label for=\"firstname\" class=\"control-label vertical-align col-md-5\">\r\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"isprivate") : depth0),{"name":"if","hash":{},"fn":container.program(7, data, 0),"inverse":container.program(9, data, 0),"data":data,"loc":{"start":{"line":45,"column":24},"end":{"line":49,"column":31}}})) != null ? stack1 : "")
    + "                    </label>\r\n                    <div class=\"col-md-7 form-control-static\">\r\n                        "
    + alias2(require("C:\\Users\\Admin\\Coding\\app-cash-register-ui\\app\\scripts\\modules\\common\\templates\\helpers\\fullRelationName.js").call(alias1,depth0,{"name":"common$fullRelationName","hash":{},"data":data,"loc":{"start":{"line":52,"column":24},"end":{"line":52,"column":53}}}))
    + "\r\n                    </div>\r\n                </div>\r\n\r\n                <div class=\"form-group row\">\r\n                    <label class=\"control-label vertical-align col-md-5\">"
    + alias2(require("C:\\Users\\Admin\\Coding\\app-cash-register-ui\\app\\scripts\\modules\\common\\templates\\helpers\\translate.js").call(alias1,"address",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":57,"column":73},"end":{"line":57,"column":103}}}))
    + "</label>\r\n                    <div class=\"col-md-7 form-control-static\">\r\n                        "
    + alias2(alias3(((stack1 = (depth0 != null ? lookupProperty(depth0,"contact_address") : depth0)) != null ? lookupProperty(stack1,"street") : stack1), depth0))
    + " "
    + alias2(alias3(((stack1 = (depth0 != null ? lookupProperty(depth0,"contact_address") : depth0)) != null ? lookupProperty(stack1,"streetnumber") : stack1), depth0))
    + " "
    + alias2(alias3(((stack1 = (depth0 != null ? lookupProperty(depth0,"contact_address") : depth0)) != null ? lookupProperty(stack1,"flatnumber") : stack1), depth0))
    + "<br>\r\n                        "
    + alias2(alias3(((stack1 = (depth0 != null ? lookupProperty(depth0,"contact_address") : depth0)) != null ? lookupProperty(stack1,"zipcode") : stack1), depth0))
    + " "
    + alias2(alias3(((stack1 = (depth0 != null ? lookupProperty(depth0,"contact_address") : depth0)) != null ? lookupProperty(stack1,"city") : stack1), depth0))
    + "<br>\r\n                        "
    + alias2(((helper = (helper = lookupProperty(helpers,"country") || (depth0 != null ? lookupProperty(depth0,"country") : depth0)) != null ? helper : alias4),(typeof helper === alias5 ? helper.call(alias1,{"name":"country","hash":{},"data":data,"loc":{"start":{"line":61,"column":24},"end":{"line":61,"column":35}}}) : helper)))
    + "\r\n                    </div>\r\n                </div>\r\n\r\n                <div class=\"form-group row\">\r\n                    <label for=\"email\" class=\"control-label vertical-align col-md-5\">\r\n                        "
    + alias2(require("C:\\Users\\Admin\\Coding\\app-cash-register-ui\\app\\scripts\\modules\\common\\templates\\helpers\\translate.js").call(alias1,"email",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":67,"column":24},"end":{"line":67,"column":52}}}))
    + "\r\n                    </label>\r\n                    <div class=\"col-md-7 form-control-static\">\r\n                        "
    + alias2(require("C:\\Users\\Admin\\Coding\\app-cash-register-ui\\app\\scripts\\modules\\common\\templates\\helpers\\email.js").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"contact_set") : depth0)) != null ? lookupProperty(stack1,"email") : stack1),{"name":"common$email","hash":{},"data":data,"loc":{"start":{"line":70,"column":24},"end":{"line":70,"column":58}}}))
    + "\r\n                    </div>\r\n                </div>\r\n\r\n                <div class=\"form-group row\">\r\n                    <label for=\"email\" class=\"control-label vertical-align col-md-5\">\r\n                        "
    + alias2(require("C:\\Users\\Admin\\Coding\\app-cash-register-ui\\app\\scripts\\modules\\common\\templates\\helpers\\translate.js").call(alias1,"phone",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":76,"column":24},"end":{"line":76,"column":52}}}))
    + "\r\n                    </label>\r\n                    <div class=\"col-md-7 form-control-static\">\r\n                        "
    + alias2(alias3(((stack1 = (depth0 != null ? lookupProperty(depth0,"contact_set") : depth0)) != null ? lookupProperty(stack1,"phone") : stack1), depth0))
    + "\r\n                    </div>\r\n                </div>\r\n\r\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"isprivate") : depth0),{"name":"if","hash":{},"fn":container.program(11, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":83,"column":16},"end":{"line":94,"column":23}}})) != null ? stack1 : "")
    + "\r\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"isInvoiceFeatureEnabled") : depth0),{"name":"if","hash":{},"fn":container.program(14, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":96,"column":16},"end":{"line":110,"column":23}}})) != null ? stack1 : "")
    + "\r\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"isLoyaltyFeatureEnabled") : depth0),{"name":"if","hash":{},"fn":container.program(17, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":112,"column":16},"end":{"line":141,"column":23}}})) != null ? stack1 : "")
    + "\r\n            </div>\r\n\r\n            <div class=\"col-xs-6\">\r\n\r\n                <div class=\"form-group row\">\r\n                    <label for=\"email\" class=\"control-label vertical-align col-md-5\">"
    + alias2(require("C:\\Users\\Admin\\Coding\\app-cash-register-ui\\app\\scripts\\modules\\common\\templates\\helpers\\translate.js").call(alias1,"language",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":148,"column":85},"end":{"line":149,"column":40}}}))
    + "</label>\r\n                    <div class=\"col-md-7 form-control-static\">\r\n                        "
    + alias2(((helper = (helper = lookupProperty(helpers,"language") || (depth0 != null ? lookupProperty(depth0,"language") : depth0)) != null ? helper : alias4),(typeof helper === alias5 ? helper.call(alias1,{"name":"language","hash":{},"data":data,"loc":{"start":{"line":151,"column":24},"end":{"line":151,"column":36}}}) : helper)))
    + "\r\n                    </div>\r\n                </div>\r\n\r\n                <div class=\"form-group row\">\r\n                    <label for=\"email\" class=\"control-label vertical-align col-md-5\">"
    + alias2(require("C:\\Users\\Admin\\Coding\\app-cash-register-ui\\app\\scripts\\modules\\common\\templates\\helpers\\translate.js").call(alias1,"communication",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":156,"column":85},"end":{"line":157,"column":45}}}))
    + "</label>\r\n                    <div class=\"col-md-7 form-control-static\">\r\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"contact_set") : depth0)) != null ? lookupProperty(stack1,"allow_general_communication") : stack1),{"name":"if","hash":{},"fn":container.program(26, data, 0),"inverse":container.program(28, data, 0),"data":data,"loc":{"start":{"line":159,"column":24},"end":{"line":163,"column":31}}})) != null ? stack1 : "")
    + "                        <i class=\"fas fa-mail-bulk communication-icon\"></i>\r\n                        "
    + alias2(require("C:\\Users\\Admin\\Coding\\app-cash-register-ui\\app\\scripts\\modules\\common\\templates\\helpers\\translate.js").call(alias1,"general",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":165,"column":24},"end":{"line":165,"column":54}}}))
    + "\r\n                    </div>\r\n                    <div class=\"col-md-7 col-md-offset-5 form-control-static\">\r\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"contact_set") : depth0)) != null ? lookupProperty(stack1,"allow_offer_communication") : stack1),{"name":"if","hash":{},"fn":container.program(26, data, 0),"inverse":container.program(28, data, 0),"data":data,"loc":{"start":{"line":168,"column":24},"end":{"line":172,"column":31}}})) != null ? stack1 : "")
    + "                        <i class=\"fas fa-bullhorn communication-icon\"></i>\r\n                        "
    + alias2(require("C:\\Users\\Admin\\Coding\\app-cash-register-ui\\app\\scripts\\modules\\common\\templates\\helpers\\translate.js").call(alias1,"sales",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":174,"column":24},"end":{"line":174,"column":52}}}))
    + "\r\n                    </div>\r\n                    <div class=\"col-md-7 col-md-offset-5 form-control-static\">\r\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"contact_set") : depth0)) != null ? lookupProperty(stack1,"allow_special_communication") : stack1),{"name":"if","hash":{},"fn":container.program(26, data, 0),"inverse":container.program(28, data, 0),"data":data,"loc":{"start":{"line":177,"column":24},"end":{"line":181,"column":31}}})) != null ? stack1 : "")
    + "                        <i class=\"fas fa-comments communication-icon\"></i>\r\n                        "
    + alias2(require("C:\\Users\\Admin\\Coding\\app-cash-register-ui\\app\\scripts\\modules\\common\\templates\\helpers\\translate.js").call(alias1,"special",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":183,"column":24},"end":{"line":183,"column":54}}}))
    + "\r\n                    </div>\r\n                </div>\r\n\r\n                <div class=\"form-group row\">\r\n                    <label for=\"email\" class=\"control-label vertical-align col-md-5\">"
    + alias2(require("C:\\Users\\Admin\\Coding\\app-cash-register-ui\\app\\scripts\\modules\\common\\templates\\helpers\\translate.js").call(alias1,"permanent_discount",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":188,"column":85},"end":{"line":189,"column":50}}}))
    + "</label>\r\n                    <div class=\"col-md-7 form-control-static\">\r\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"permanent_discount") : depth0),{"name":"if","hash":{},"fn":container.program(30, data, 0),"inverse":container.program(32, data, 0),"data":data,"loc":{"start":{"line":191,"column":24},"end":{"line":195,"column":31}}})) != null ? stack1 : "")
    + "                        <button class=\"btn btn-primary btn-regular\" data-action=\"change-discount\">\r\n                            "
    + alias2(require("C:\\Users\\Admin\\Coding\\app-cash-register-ui\\app\\scripts\\modules\\common\\templates\\helpers\\translate.js").call(alias1,"change",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":197,"column":28},"end":{"line":197,"column":57}}}))
    + "\r\n                        </button>\r\n                    </div>\r\n                </div>\r\n            </div>\r\n\r\n        </div>\r\n\r\n\r\n        <br>\r\n        <div class=\"form-group form-actions\">\r\n            <button class=\"btn btn-default btn-regular\" data-action=\"back\">\r\n                <i class=\"fas fa-arrow-left\" style=\"margin-right: 5px;\"></i> "
    + alias2(require("C:\\Users\\Admin\\Coding\\app-cash-register-ui\\app\\scripts\\modules\\common\\templates\\helpers\\translate.js").call(alias1,"go_back",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":209,"column":77},"end":{"line":209,"column":107}}}))
    + "\r\n            </button>\r\n\r\n"
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"isInWebOrAppMode") : depth0),{"name":"unless","hash":{},"fn":container.program(34, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":212,"column":12},"end":{"line":216,"column":23}}})) != null ? stack1 : "")
    + "        </div>\r\n\r\n    </form>\r\n\r\n</div>\r\n\r\n<div data-region=\"popup\" class=\"hidden\"></div>\r\n";
},"useData":true});