var Handlebars = require("handlebars");
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <div class=\"col-xs-8\">\r\n            <strong>"
    + alias2(require("C:\\Users\\Admin\\Coding\\app-cash-register-ui\\app\\scripts\\modules\\common\\templates\\helpers\\translate.js").call(alias1,"discount",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":11,"column":20},"end":{"line":11,"column":51}}}))
    + "</strong>\r\n        </div>\r\n        <div class=\"col-xs-4 text-right\">\r\n            "
    + alias2(require("C:\\Users\\Admin\\Coding\\app-cash-register-ui\\app\\scripts\\modules\\common\\templates\\helpers\\tofixed.js").call(alias1,(depth0 != null ? lookupProperty(depth0,"total_discount_wt") : depth0),2,{"name":"common$tofixed","hash":{},"data":data,"loc":{"start":{"line":14,"column":12},"end":{"line":14,"column":50}}}))
    + "\r\n        </div>\r\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"row\">\r\n    <div class=\"col-xs-8\">\r\n        <strong>"
    + alias2(require("C:\\Users\\Admin\\Coding\\app-cash-register-ui\\app\\scripts\\modules\\common\\templates\\helpers\\translate.js").call(alias1,"subtotal",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":3,"column":16},"end":{"line":3,"column":47}}}))
    + "</strong>\r\n    </div>\r\n    <div class=\"col-xs-4 text-right\">\r\n        "
    + alias2(require("C:\\Users\\Admin\\Coding\\app-cash-register-ui\\app\\scripts\\modules\\common\\templates\\helpers\\tofixed.js").call(alias1,(depth0 != null ? lookupProperty(depth0,"subtotal") : depth0),2,{"name":"common$tofixed","hash":{},"data":data,"loc":{"start":{"line":6,"column":8},"end":{"line":6,"column":37}}}))
    + "\r\n    </div>\r\n\r\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"show_total_discount_wt") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":9,"column":4},"end":{"line":16,"column":11}}})) != null ? stack1 : "")
    + "\r\n    <div class=\"col-xs-8\">\r\n        <strong>"
    + alias2(require("C:\\Users\\Admin\\Coding\\app-cash-register-ui\\app\\scripts\\modules\\common\\templates\\helpers\\translate.js").call(alias1,"total_to_be_paid",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":19,"column":16},"end":{"line":19,"column":55}}}))
    + "</strong>\r\n    </div>\r\n    <div class=\"col-xs-4 text-right\">\r\n        "
    + alias2(require("C:\\Users\\Admin\\Coding\\app-cash-register-ui\\app\\scripts\\modules\\common\\templates\\helpers\\tofixed.js").call(alias1,(depth0 != null ? lookupProperty(depth0,"total_wt") : depth0),2,{"name":"common$tofixed","hash":{},"data":data,"loc":{"start":{"line":22,"column":8},"end":{"line":22,"column":37}}}))
    + "\r\n    </div>\r\n</div>";
},"useData":true});