var Handlebars = require("handlebars");
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"order-information-container\">\r\n    <div class=\"company-logo-header\" style=\"background-image: url('"
    + alias2(((helper = (helper = lookupProperty(helpers,"companyLogo") || (depth0 != null ? lookupProperty(depth0,"companyLogo") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"companyLogo","hash":{},"data":data,"loc":{"start":{"line":2,"column":67},"end":{"line":2,"column":82}}}) : helper)))
    + "')\"></div>\r\n    <div class=\"order-information-products\" data-region=\"products\"></div>\r\n    <div class=\"order-information-totals\" data-region=\"totals\"></div>\r\n</div>\r\n<div class=\"order-information-footer\">\r\n    <div class=\"powered-by\">\r\n        <span>"
    + alias2(require("C:\\Users\\Admin\\Coding\\app-cash-register-ui\\app\\scripts\\modules\\common\\templates\\helpers\\translate.js").call(alias1,"powered_by",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":8,"column":14},"end":{"line":8,"column":47}}}))
    + "</span>\r\n        <img src=\"./images/logo-white-inline.png\" alt=\"Storekeeper\" class=\"img-responsive\">\r\n    </div>\r\n</div>";
},"useData":true});