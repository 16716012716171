var Handlebars = require("handlebars");
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            "
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"salutation") || (depth0 != null ? lookupProperty(depth0,"salutation") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"salutation","hash":{},"data":data,"loc":{"start":{"line":4,"column":12},"end":{"line":4,"column":26}}}) : helper)))
    + "\r\n";
},"3":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            "
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"name") || (depth0 != null ? lookupProperty(depth0,"name") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"name","hash":{},"data":data,"loc":{"start":{"line":7,"column":12},"end":{"line":7,"column":20}}}) : helper)))
    + "\r\n";
},"5":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            "
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"street") || (depth0 != null ? lookupProperty(depth0,"street") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"street","hash":{},"data":data,"loc":{"start":{"line":12,"column":12},"end":{"line":12,"column":22}}}) : helper)))
    + "\r\n";
},"7":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            "
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"street_number") || (depth0 != null ? lookupProperty(depth0,"street_number") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"street_number","hash":{},"data":data,"loc":{"start":{"line":15,"column":12},"end":{"line":15,"column":29}}}) : helper)))
    + "\r\n";
},"9":function(container,depth0,helpers,partials,data) {
    return "data-change";
},"11":function(container,depth0,helpers,partials,data) {
    return "            <i class=\"fas fa-2x fa-times-circle text-primary\"></i>\r\n";
},"13":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            "
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"postal_code") || (depth0 != null ? lookupProperty(depth0,"postal_code") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"postal_code","hash":{},"data":data,"loc":{"start":{"line":25,"column":12},"end":{"line":25,"column":27}}}) : helper)))
    + "\r\n";
},"15":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            "
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"city") || (depth0 != null ? lookupProperty(depth0,"city") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"city","hash":{},"data":data,"loc":{"start":{"line":28,"column":12},"end":{"line":28,"column":20}}}) : helper)))
    + "\r\n";
},"17":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            "
    + container.escapeExpression(require("C:\\Users\\Admin\\Coding\\app-cash-register-ui\\app\\scripts\\modules\\common\\templates\\helpers\\country.js").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"country") : depth0),{"name":"common$country","hash":{},"data":data,"loc":{"start":{"line":33,"column":12},"end":{"line":33,"column":38}}}))
    + "\r\n";
},"19":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <strong>\r\n                "
    + alias2(require("C:\\Users\\Admin\\Coding\\app-cash-register-ui\\app\\scripts\\modules\\common\\templates\\helpers\\translate.js").call(alias1,"geb",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":40,"column":16},"end":{"line":40,"column":42}}}))
    + "\r\n            </strong>\r\n            "
    + alias2(require("C:\\Users\\Admin\\Coding\\app-cash-register-ui\\app\\scripts\\modules\\common\\templates\\helpers\\datetime.js").call(alias1,(depth0 != null ? lookupProperty(depth0,"birthdate") : depth0),"LL",{"name":"common$datetime","hash":{},"data":data,"loc":{"start":{"line":42,"column":12},"end":{"line":42,"column":46}}}))
    + " ("
    + alias2(((helper = (helper = lookupProperty(helpers,"age") || (depth0 != null ? lookupProperty(depth0,"age") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"age","hash":{},"data":data,"loc":{"start":{"line":42,"column":48},"end":{"line":42,"column":55}}}) : helper)))
    + " "
    + alias2(require("C:\\Users\\Admin\\Coding\\app-cash-register-ui\\app\\scripts\\modules\\common\\templates\\helpers\\translate.js").call(alias1,"year",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":42,"column":56},"end":{"line":42,"column":83}}}))
    + ")\r\n";
},"21":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <span class=\"text-danger\">(-"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"permanent_discount") || (depth0 != null ? lookupProperty(depth0,"permanent_discount") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"permanent_discount","hash":{},"data":data,"loc":{"start":{"line":47,"column":40},"end":{"line":47,"column":62}}}) : helper)))
    + "%)</span>\r\n";
},"23":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            "
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"membercard_note") || (depth0 != null ? lookupProperty(depth0,"membercard_note") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"membercard_note","hash":{},"data":data,"loc":{"start":{"line":50,"column":12},"end":{"line":50,"column":31}}}) : helper)))
    + "\r\n";
},"25":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <i class=\"fas fa-address-card text-primary\"></i>\r\n            "
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"membercard_code") || (depth0 != null ? lookupProperty(depth0,"membercard_code") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"membercard_code","hash":{},"data":data,"loc":{"start":{"line":56,"column":12},"end":{"line":56,"column":31}}}) : helper)))
    + "\r\n";
},"27":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            "
    + container.escapeExpression(require("C:\\Users\\Admin\\Coding\\app-cash-register-ui\\app\\scripts\\modules\\common\\templates\\helpers\\datetime.js").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"membercard_duedate") : depth0),"MM/YY",{"name":"common$datetime","hash":{},"data":data,"loc":{"start":{"line":62,"column":12},"end":{"line":62,"column":58}}}))
    + "\r\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"designer-box rounded customer-button-grid\">\r\n    <span class=\"name text-ellipsis\" data-edit>\r\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"salutation") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":3,"column":8},"end":{"line":5,"column":15}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"name") : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":6,"column":8},"end":{"line":8,"column":15}}})) != null ? stack1 : "")
    + "    </span>\r\n    <span class=\"street text-ellipsis\" data-edit>\r\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"street") : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":11,"column":8},"end":{"line":13,"column":15}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"street_number") : depth0),{"name":"if","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":14,"column":8},"end":{"line":16,"column":15}}})) != null ? stack1 : "")
    + "    </span>\r\n    <span class=\"change\" "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"allowChange") : depth0),{"name":"if","hash":{},"fn":container.program(9, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":18,"column":25},"end":{"line":18,"column":62}}})) != null ? stack1 : "")
    + ">\r\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"allowChange") : depth0),{"name":"if","hash":{},"fn":container.program(11, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":19,"column":8},"end":{"line":21,"column":15}}})) != null ? stack1 : "")
    + "    </span>\r\n    <span class=\"postal text-ellipsis\" data-edit>\r\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"postal_code") : depth0),{"name":"if","hash":{},"fn":container.program(13, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":24,"column":8},"end":{"line":26,"column":15}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"city") : depth0),{"name":"if","hash":{},"fn":container.program(15, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":27,"column":8},"end":{"line":29,"column":15}}})) != null ? stack1 : "")
    + "    </span>\r\n    <span class=\"country text-ellipsis\" data-edit>\r\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"country") : depth0),{"name":"if","hash":{},"fn":container.program(17, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":32,"column":8},"end":{"line":34,"column":15}}})) != null ? stack1 : "")
    + "    </span>\r\n    <span class=\"empty\" data-edit></span>\r\n    <span class=\"birth text-ellipsis\" data-edit>\r\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"birthdate") : depth0),{"name":"if","hash":{},"fn":container.program(19, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":38,"column":8},"end":{"line":43,"column":15}}})) != null ? stack1 : "")
    + "    </span>\r\n    <span class=\"note text-ellipsis\" data-edit>\r\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"permanent_discount") : depth0),{"name":"if","hash":{},"fn":container.program(21, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":46,"column":8},"end":{"line":48,"column":15}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"membercard_note") : depth0),{"name":"if","hash":{},"fn":container.program(23, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":49,"column":8},"end":{"line":51,"column":15}}})) != null ? stack1 : "")
    + "    </span>\r\n    <span class=\"card\" data-edit>\r\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"membercard_code") : depth0),{"name":"if","hash":{},"fn":container.program(25, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":54,"column":8},"end":{"line":57,"column":15}}})) != null ? stack1 : "")
    + "    </span>\r\n\r\n    <span class=\"expire\" data-edit>\r\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"membercard_duedate") : depth0),{"name":"if","hash":{},"fn":container.program(27, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":61,"column":8},"end":{"line":63,"column":15}}})) != null ? stack1 : "")
    + "    </span>\r\n</div>\r\n\r\n<div data-regions=\"popup\" class=\"hidden\"></div>\r\n";
},"useData":true});