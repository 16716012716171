var Handlebars = require("handlebars");
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<td>\r\n    <strong>#"
    + alias2(((helper = (helper = lookupProperty(helpers,"number") || (depth0 != null ? lookupProperty(depth0,"number") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"number","hash":{},"data":data,"loc":{"start":{"line":2,"column":13},"end":{"line":2,"column":23}}}) : helper)))
    + "</strong>\r\n    <br/>\r\n    "
    + alias2(require("C:\\Users\\Admin\\Coding\\app-cash-register-ui\\app\\scripts\\modules\\common\\templates\\helpers\\datetime.js").call(alias1,(depth0 != null ? lookupProperty(depth0,"date_created") : depth0),"LLL",{"name":"common$datetime","hash":{},"data":data,"loc":{"start":{"line":4,"column":4},"end":{"line":4,"column":42}}}))
    + "\r\n</td>\r\n<td>\r\n    <strong>"
    + alias2(require("C:\\Users\\Admin\\Coding\\app-cash-register-ui\\app\\scripts\\modules\\common\\templates\\helpers\\currencyFormat.js").call(alias1,(depth0 != null ? lookupProperty(depth0,"currency_iso3") : depth0),(depth0 != null ? lookupProperty(depth0,"value_outstanding_wt") : depth0),{"name":"common$currencyFormat","hash":{},"data":data,"loc":{"start":{"line":7,"column":12},"end":{"line":7,"column":73}}}))
    + "</strong>\r\n</td>\r\n<td>\r\n    <button class=\"btn btn-primary btn-regular\" data-action=\"pay\">\r\n        <i class=\"fas fa-euro-sign\"></i>\r\n    </button>\r\n</td>\r\n";
},"useData":true});