var Handlebars = require("handlebars");
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <div class=\"image effect-"
    + alias2(alias1((depths[1] != null ? lookupProperty(depths[1],"effect") : depths[1]), depth0))
    + " alignment-"
    + alias2(alias1((depths[1] != null ? lookupProperty(depths[1],"alignment") : depths[1]), depth0))
    + "\" style=\"background-image: url('"
    + alias2(alias1(depth0, depth0))
    + "'); background-color: "
    + alias2(alias1((depths[1] != null ? lookupProperty(depths[1],"background") : depths[1]), depth0))
    + "\" data-ui=\"images\"></div>\r\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"each").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"images") : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":0},"end":{"line":3,"column":9}}})) != null ? stack1 : "");
},"useData":true,"useDepths":true});