var Handlebars = require("handlebars");
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "            "
    + container.escapeExpression(require("C:\\Users\\Admin\\Coding\\app-cash-register-ui\\app\\scripts\\modules\\common\\templates\\helpers\\translate.js").call(depth0 != null ? depth0 : (container.nullContext || {}),"create_a_new_customer",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":5,"column":12},"end":{"line":5,"column":56}}}))
    + "\r\n";
},"3":function(container,depth0,helpers,partials,data) {
    return "            "
    + container.escapeExpression(require("C:\\Users\\Admin\\Coding\\app-cash-register-ui\\app\\scripts\\modules\\common\\templates\\helpers\\translate.js").call(depth0 != null ? depth0 : (container.nullContext || {}),"create_a_new_company",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":7,"column":12},"end":{"line":7,"column":55}}}))
    + "\r\n";
},"5":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression;

  return "                <button class=\"btn btn-primary btn-regular pull-right\" data-action=\"save\">\r\n                    <i class=\"fas fa-user-plus\" style=\"margin-right: 5px;\"></i> "
    + alias2(require("C:\\Users\\Admin\\Coding\\app-cash-register-ui\\app\\scripts\\modules\\common\\templates\\helpers\\translate.js").call(alias1,"add_new_customer",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":22,"column":80},"end":{"line":22,"column":119}}}))
    + "\r\n                </button>\r\n                <button class=\"btn btn-primary btn-regular pull-right\" data-action=\"save-and-select\" style=\"margin-right: 15px\">\r\n                    <i class=\"fas fa-user-plus\" style=\"margin-right: 5px;\"></i> "
    + alias2(require("C:\\Users\\Admin\\Coding\\app-cash-register-ui\\app\\scripts\\modules\\common\\templates\\helpers\\translate.js").call(alias1,"add_new_customer_and_select",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":25,"column":80},"end":{"line":25,"column":130}}}))
    + "\r\n                </button>\r\n";
},"7":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression;

  return "                <button class=\"btn btn-primary btn-regular pull-right\" data-action=\"save\">\r\n                    <i class=\"fas fa-user-plus\" style=\"margin-right: 5px;\"></i> "
    + alias2(require("C:\\Users\\Admin\\Coding\\app-cash-register-ui\\app\\scripts\\modules\\common\\templates\\helpers\\translate.js").call(alias1,"add_new_company",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":29,"column":80},"end":{"line":29,"column":118}}}))
    + "\r\n                </button>\r\n                <button class=\"btn btn-primary btn-regular pull-right\" data-action=\"save-and-select\" style=\"margin-right: 15px\">\r\n                    <i class=\"fas fa-user-plus\" style=\"margin-right: 5px;\"></i> "
    + alias2(require("C:\\Users\\Admin\\Coding\\app-cash-register-ui\\app\\scripts\\modules\\common\\templates\\helpers\\translate.js").call(alias1,"add_new_company_and_select",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":32,"column":80},"end":{"line":32,"column":129}}}))
    + "\r\n                </button>\r\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"block full designer-box rounded\">\r\n\r\n    <h2>\r\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"is_private") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data,"loc":{"start":{"line":4,"column":8},"end":{"line":8,"column":15}}})) != null ? stack1 : "")
    + "    </h2>\r\n    <hr>\r\n\r\n    <form onsubmit=\"return false;\" method=\"post\">\r\n"
    + ((stack1 = container.invokePartial(require("C:\\Users\\Admin\\Coding\\app-cash-register-ui\\app\\scripts\\modules\\shop.cash-register-retail\\templates\\customers\\formPartial.hbs"),depth0,{"name":"modules/shop.cash-register-retail/templates/customers/formPartial","data":data,"indent":"        ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "\r\n        <br>\r\n        <div class=\"form-group form-actions\">\r\n            <button class=\"btn btn-default btn-regular\" data-action=\"back\">\r\n                <i class=\"fas fa-arrow-left\" style=\"margin-right: 5px;\"></i> "
    + container.escapeExpression(require("C:\\Users\\Admin\\Coding\\app-cash-register-ui\\app\\scripts\\modules\\common\\templates\\helpers\\translate.js").call(alias1,"go_back",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":18,"column":77},"end":{"line":18,"column":107}}}))
    + "\r\n            </button>\r\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"is_private") : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.program(7, data, 0),"data":data,"loc":{"start":{"line":20,"column":12},"end":{"line":34,"column":19}}})) != null ? stack1 : "")
    + "        </div>\r\n\r\n    </form>\r\n\r\n</div>\r\n\r\n<div data-region=\"popup\" class=\"hidden\"></div>\r\n";
},"usePartial":true,"useData":true});